import _ from 'underscore'
import TextField from '../components/shared/TextField';
import MoneyField from '../components/shared/MoneyField';
import React from 'react';
import Toggle from '../components/shared/Toggle';
import SmartSelect from '../components/shared/SmartSelect';
import Select from '../components/shared/Select';
import TimeFrame from '../components/shared/TimeFrame';
import { moneyFromInt, moneyToInt } from './parser'
import DateField from '../components/shared/DateField';
import FieldInput from '../components/shared/FieldInput';

function generateForm(schema, formHandler, data={}, errors=[]){
  let { properties, required } = schema;
  if (!properties) return

  const errs = _.object(errors)
  const errFields = _.map(errors, _.first)  

  let { hidden, readonly } = properties.metadata
  let props = _.omit(properties, 'metadata')
  let fieldNames = _.reject(Object.keys(props), 
    (prop) => hidden?.includes(prop) || readonly?.includes(prop))
  let fields = []
  fieldNames.forEach((prop, index) => {
    // 
    fields.push(switchComponent(props[prop].component || 'TextField', {
      error: _.includes(errFields, prop) && errs[prop],
      key: `${index}-${prop}`,
      name: prop,
      required: required && required.includes(prop),
      ...props[prop].attrs,
      value: data[prop] || ''
    }, formHandler))
  })
  return fields
}

function switchComponent(as, props, formHandler) {
  const {name, value} = props
  switch (as) {
    case 'hidden':
      return <input type='hidden' id={props.name} {...props}/>
    case 'TimeFrame':
      return <TimeFrame {...props} onChange={formHandler}/>;
    case 'Select':
      return (
        <Select {...props} onChange={(v) => formHandler(name, v.target.value)}>
          { props.addblank && <option></option> }
          {props.options.map((p) => <option value={p.value} selected={p.value === props.defaultValue}>{p.key}</option>)}
        </Select>
      );
    case 'SmartSelect':
      return <SmartSelect {...props} onChange={(v) => formHandler(name, v.target.value)}/>;
    case 'Toggle':
      return <Toggle {...props} value={!!value} onChange={(v) => formHandler(name, v.target.checked)} checked={!!value}/>
    case 'date':
      return <DateField {...props} onChange={(v) => formHandler && formHandler(name, v)}/>;
    case 'FieldInput':
      return <FieldInput {...props} onChange={(v) => formHandler && formHandler(name, v.target.value)}/>;
    case 'MoneyField':
      return <MoneyField 
        {...props} 
        value={ props.value ? moneyToInt(props.value) : 0 } 
        onChange={(v) => formHandler && formHandler(name, moneyFromInt(v.target.value))}
      />;
    default:
      return <TextField {...props} value={props.value} onChange={(v) => formHandler && formHandler(name, v.target.value)}/>;
  }
}

export const randomId = () => {
  return (Math.random() + 1).toString(36).substring(7)
}

export const bitwiseHash = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export const handleFormSubmit = (callback) => {return {onKeyDown: (e) => e.keyCode === 13 && callback() }}

export default generateForm;