import Modal from "../Modal";

export default function AckModal({ title, text, ok='Understood', onClose, children }) {
  return (
    <Modal title={title} sticky cancellable={false} actions={(
      <div className="control">
        <button className="button is-bordered is-dark" onClick={onClose}>{ok}</button>
      </div>
    )}>
      <span dangerouslySetInnerHTML={{__html: text}}/>
      <div>
        { children }
      </div>
    </Modal>
  )
}