import React from "react";
import useAuth from "../context/Auth";
import useData from "../context/Routing";
import useIngress  from '../context/Ingress';
import AlertMessage from './shared/AlertMessage'
import Link from "./shared/Link";
import { isIOS } from '../utils/platform'
import icons from '../data/icons'
import IconText from './shared/IconText'
import setScroll from '../utils/scroll'

export default function Navigator ({children}) {
  const auth = useAuth();
  const data = useData();
  const ingress = useIngress();
  const { session, userData, clearMessage } = auth
  const [menu, openMenu] = React.useState(false)
  const [ links, setLinks] = React.useState([])
  const [menuItems, setMenuItems] = React.useState([])
  
  const closeMenu = () => { openMenu(false); setScroll(true) }
  const handleLogout = () => auth.endSession().then(data.emptyState()).then(ingress.clearHistory())

  const btnStyle = (path) =>  ingress.path.substr(1).includes(path) ? 'is-primary is-bordered border-grey' : 'has-text-grey-dark is-outlined'
  const menuButton = (path) => (
    <Link classes={`button px-4 mx-1  ${btnStyle(path)}`} onClick={() => { ingress.paths[path](); closeMenu() }}>
      <span className="icon is-large">
        <i className={`fas fa-${icons[path]}`}/>
      </span>
    </Link>
  )

  const menuText = (path, label) => <IconText text={label} icon={path}/>

  const menuListItem = (path, label, action) => (
    <li className={ingress.path.substr(1) == path ? "is-selected is-rounded has-background-primary" : ''}>
      {ingress.link(menuText(path, label), `has-text-${ingress.path.substr(1) == path ? 'white' : 'grey-dark'}` , action, closeMenu)}
    </li>
  )

  React.useEffect(() => {
    if (!ingress.paths) return

    let lks = []
    if (session) {
      lks.push(menuButton('dashboard'))
      lks.push(
        <div className="field has-addons">
          {menuButton('projection')}
          {menuButton('transaction')}
          {menuButton('expense')}
          {menuButton('deposit')}
          {menuButton('bank')}
        </div>
      )
    } else {
      lks.push(ingress.link(menuText('dashboard', 'Home'), 'button is-outlined is-dark is-rounded', ingress.paths.home, closeMenu))
      lks.push(ingress.link(menuText('auth', 'Login'), 'button is-rounded is-primary', ingress.paths.login, closeMenu))
    }

    setLinks(lks)
    
    setMenuItems([
      <p className="menu-label">
        General
      </p>,
      <ul className="menu-list">
        { menuListItem('dashboard', 'Dashboard', ingress.paths.dashboard) }
        { menuListItem('projection', 'Projections', ingress.paths.projection) }
      </ul>,
      <p className="menu-label">
        Manage
      </p>,
      <ul className="menu-list">
        { menuListItem('transaction', 'Transactions', ingress.paths.transaction) }
        { menuListItem('expense', 'Expenses', ingress.paths.expense) }
        { menuListItem('deposit', 'Deposits', ingress.paths.deposit) }
        { menuListItem('bank', 'Banks', ingress.paths.bank) }
        { menuListItem('category', 'Tags', ingress.paths.category) }
      </ul>,
      <p className="menu-label">
        {`Profile (${userData?.username})`}
      </p>,
      <ul className="menu-list">
        { menuListItem('profile', 'Settings', ingress.paths.profile) }
        { menuListItem('questions', 'Questions', ingress.paths.questions) }
        { menuListItem('auth', 'Logout', handleLogout) }
      </ul>
    ])
  }, [session, ingress.paths, ingress.path])

  // TODO: Replace navbar with a 'Level' that has no auto-magic
  return (
    <React.Fragment >
      {menu && <div className="modal-background" />}
      <div className={menu ? 'is-hidden':'mb-6'}>
        {children }
      </div>
      <nav className={`navbar is-rounded bottomout is-mobile is-bottom not-selectable ${isIOS() ? 'pb-4' : ''}`} role="navigation" aria-label="main navigation" style={{display: 'block'}}>
        <div className="aside is-floating">
          <AlertMessage style={auth?.userData?.messageType ? auth?.userData?.messageType : 'is-info'} onClear={clearMessage} message={userData?.message}/>
        </div>
        { session && menu ? (<aside>{ menuItems }</aside>) : <div/> }
        <div className="navbar-brand not-selectable"  style={{justifyContent: 'space-between', overflowX: 'auto'}}>
          { links && links.length > 1 && links.map((link, i) => <div key={`m${i}`} className="navbar-item px-1">{link}</div> ) }
          { session && 
            <div key='ham' className="navbar-item px-1">
              <button className="button no-shadow is-dark is-inverted" onClick={() => {openMenu(!menu); setScroll(menu)} }>
                <span className='icon'>
                  <i className={`fas fa-${menu ? 'close' : 'bars'} fa-lg`}/>
                </span>
              </button>
            </div>
          }
        </div>
      </nav>
    </React.Fragment>
  )
};
